import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5311767f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_slide_x_reverse_transition = _resolveComponent("v-slide-x-reverse-transition")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.compact)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_v_chip_group, {
            modelValue: _ctx.onTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.onTab) = $event)),
            mandatory: "",
            column: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (mobileItem, mobileItemKey) => {
                return (_openBlock(), _createBlock(_component_v_chip, {
                  key: mobileItemKey,
                  class: "ma-1",
                  "active-class": "greenPrimary text--primary",
                  outlined: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(mobileItem), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("template", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (mobileItem, mobileItemKey) => {
              return (_openBlock(), _createElementBlock("div", { key: mobileItemKey }, [
                (_ctx.onTab == mobileItemKey)
                  ? _renderSlot(_ctx.$slots, 'tabItemContent' + (mobileItemKey + 1), { key: 0 }, undefined, true)
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.compact)
      ? (_openBlock(), _createBlock(_component_v_tabs, {
          key: 1,
          modelValue: _ctx.onTab,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.onTab) = $event)),
          class: _normalizeClass(_ctx.tabClasses),
          "background-color": _ctx.background,
          color: _ctx.activeColor,
          centered: _ctx.isCentered,
          "slider-size": "3",
          "hide-slider": _ctx.isBlock || _ctx.isVertical,
          grow: _ctx.isBlock || _ctx.hasFullWidth,
          vertical: _ctx.isVertical,
          "show-arrows": _ctx.showArrows,
          "align-with-title": !_ctx.isBlock && !_ctx.isCentered && !_ctx.isVertical
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
              return (_openBlock(), _createBlock(_component_v_tab, {
                key: `${item}` + i,
                class: _normalizeClass([
            _ctx.isBlock
              ? 'mew-tab-block'
              : _ctx.isVertical || _ctx.isSmall
                ? 'mew-body font-weight-medium'
                : 'mew-heading-2',
            'capitalize'
          ]),
                ripple: !_ctx.isVertical
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item), 1)
                ]),
                _: 2
              }, 1032, ["class", "ripple"]))
            }), 128)),
            _createVNode(_component_v_window, {
              modelValue: _ctx.onTab,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.onTab) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                  return (_openBlock(), _createBlock(_component_v_window_item, {
                    key: `${item}` + i,
                    value: item,
                    "reverse-transition": 
              !_ctx.isVertical ? 'slide-x-transition' : 'slide-y-transition'
            
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, 'tabItemContent' + (i + 1), {}, undefined, true)
                    ]),
                    _: 2
                  }, 1032, ["value", "reverse-transition"]))
                }), 128))
              ]),
              _: 3
            }, 8, ["modelValue"])
          ]),
          _: 3
        }, 8, ["modelValue", "class", "background-color", "color", "centered", "hide-slider", "grow", "vertical", "show-arrows", "align-with-title"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${item}` + i
      }, [
        _createVNode(_component_v_slide_x_reverse_transition, {
          "hide-on-leave": true,
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.onTab === i)
              ? _renderSlot(_ctx.$slots, 'tabContent' + (i + 1), { key: 0 }, undefined, true)
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}