import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f50f0692"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "inset-shadow"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", {
      style: _normalizeStyle([{"position":"relative"}, `width: ${_ctx.width}; height: ${_ctx.height}`])
    }, [
      _createElementVNode("img", {
        ref: "blockie",
        src: _ctx.blockieImg,
        alt: "Blockie Image",
        style: {"display":"block","border-radius":"50%"}
      }, null, 8, _hoisted_1),
      (!_ctx.flat)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true)
    ], 4), [
      [_vShow, _ctx.address]
    ]),
    (_ctx.currency)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          alt: "icon",
          class: "currency-icon",
          src: _ctx.currency
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}